import { useLocalStorage } from "@src/appV2/lib/utils";
import {
  INTERESTED_WORK_TYPE_STORAGE_KEY,
  WorkTypeOption,
} from "@src/appV2/redesign/Onboarding/components/InterestedWorkType";
import { useIsWorkTypeOnboardingEnabled } from "@src/appV2/redesign/Onboarding/useIsWorkTypeOnboardingEnabled";

export function useSelectedWorkType() {
  const [selectedOption] = useLocalStorage<WorkTypeOption | undefined>(
    INTERESTED_WORK_TYPE_STORAGE_KEY,
    undefined
  );
  const isWorkTypeOnboardingEnabled = useIsWorkTypeOnboardingEnabled();

  return {
    selectedWorkType: selectedOption,
    isPermanentPlacementsOnboarding:
      selectedOption === WorkTypeOption.PERMANENT_PLACEMENTS && isWorkTypeOnboardingEnabled,
    isFlexOnboarding:
      selectedOption === WorkTypeOption.FLEX ||
      selectedOption === undefined ||
      !isWorkTypeOnboardingEnabled, // Default to FLEX if not set
    isFlexAndPermanentPlacementsOnboarding:
      selectedOption === WorkTypeOption.PERMANENT_AND_FLEX && isWorkTypeOnboardingEnabled,
  };
}
